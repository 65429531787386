import { ensureError } from '@orus.eu/error'
import { useCrash } from '@orus.eu/pharaoh'
import { isSuccess } from '@orus.eu/result'
import { useSearch } from '@tanstack/react-router'
import { memo, useEffect, useState } from 'react'
import { trpc } from '../../client'
import { useNavigateTo } from '../../lib/hooks/use-navigate-to-route'
import { sessionManager, useSession } from '../../lib/session'
import { GlobalLoadingState } from '../molecules/global-loading-state'
import { GenericProblemMessage } from '../organisms/generic-problem-message'
import { Page } from '../templates/page'

export default memo(function ImpersonatePage() {
  return (
    <Page>
      <ImpersonateLoader />
    </Page>
  )
})

const ImpersonateLoader = memo(function ImpersonateLoader() {
  const crash = useCrash()
  const session = useSession()
  const [status, setStatus] = useState<'loading' | 'invalid-token' | 'already-connected' | 'reloading-session'>(
    'loading',
  )
  const searchParams = useSearch({ strict: false })
  const navigateToRoot = useNavigateTo({ to: '/' })
  const token = searchParams.token

  useEffect(() => {
    if (!token || !session) {
      // wait for required params before doing anything
      return
    }

    if (session.user) {
      setStatus((previousStatus) =>
        previousStatus === 'reloading-session' ? 'reloading-session' : 'already-connected',
      )
      return
    }

    let cancelled = false

    trpc.sessions.useImpersonationToken.mutate(token).then(
      (result) => {
        if (cancelled) {
          return
        }

        if (isSuccess(result)) {
          sessionManager.refreshSession().then(navigateToRoot, (err) => crash(ensureError(err)))
          return
        }

        setStatus(result.problem)
      },
      (err) => {
        if (cancelled) {
          return
        }

        crash(ensureError(err))
      },
    )

    return () => {
      cancelled = true
    }
  }, [crash, navigateToRoot, session, token])

  switch (status) {
    case 'loading':
    case 'reloading-session':
      return <GlobalLoadingState />
    case 'invalid-token':
      return (
        <GenericProblemMessage
          title="Token invalide"
          principalMessage="Le token est invalide ou expiré."
          firstSubText="Pour des raison de sécurité, le token expire au bout de 30 secondes."
          secondSubText="Vous pouvez recommencer en utilisant immediatement le token généré."
        />
      )
    case 'already-connected':
      return (
        <GenericProblemMessage
          title="Déjà connecté"
          principalMessage="Use session est déjà en cours sur ce navigateur."
          firstSubText="Pour éviter toutes fausse manipulation, on n'autorise pas l'impersonification dans ce cas."
          secondSubText="Vous pouvez recommencer en utilisant une fenêtre de navigation privée."
        />
      )
  }
})
